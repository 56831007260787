import { LOGGED_USER_DETAILS, NOTIFICATIONS} from "../types";

const initialState = {
    userDetails: null,
    notifications:[]
    
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGGED_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            };
            case NOTIFICATIONS:
                return {
                    ...state,
                    notifications: action.payload,
                };
        default:
            return state;
    }
};

export default userReducer;