import CommonRoutes from "./Routes/CommonRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./scss/main.css";

function App() {
  return (
    <>
      <CommonRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
