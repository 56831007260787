export default function MainHeading({ title, HeadingButtons, backButtonHandler }) {
  return (
    <div class="content-head mb-5 d-flex align-items-start justify-content-between">
      <div className="d-flex align-items-center justify-content-start gap-4">
        {backButtonHandler && <a onClick={backButtonHandler} class="btn back-arrow" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 24 24" }} xmlSpace="preserve" class="">
            <g>
              <path d="M22 11H4.414l5.293-5.293a1 1 0 1 0-1.414-1.414l-7 7a1 1 0 0 0 0 1.414l7 7a1 1 0 0 0 1.414-1.414L4.414 13H22a1 1 0 0 0 0-2z" fill="#25476a" opacity="1" data-original="#25476a" class=""></path>
            </g>
          </svg>
        </a>}
        <h1 class="mb-0">{title}</h1>
      </div>

      {HeadingButtons && (
        <div class="heading-buttons">
          {HeadingButtons.map((button, index) => (
            <button
              onClick={button.function} // Call the function directly
              key={index}
              class="btn btn-primary border-white  px-5"
            >
              {button.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
