import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { toast } from "react-toastify";

const DocumentModal = ({
  isOpen,
  toggle,
  documentData,
  handleAccept,
  handleReject,
}) => {
  //   if (!documentData) return null;

  return (
    <Modal
      className="modal-dialog modal-xl modal-dialog-centered dlt-modal"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Document Verification</ModalHeader>
      <ModalBody>
        <h5>{documentData?.name}</h5>
        <p>
          <strong>Document Type:</strong> {"Driving License"}
        </p>
        <p>
          <strong>Status:</strong>{" "}
          {documentData?.isDocVerified == true ? "Yes" : "No"}
        </p>
        <p>
          <strong>Uploaded On:</strong>{" "}
          {new Date(documentData?.createdAt).toLocaleDateString()}
        </p>
        <div className="row doc-verify">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-4">
                <img
                  src={`data:image/jpeg;base64,${documentData?.dlBackPhoto}`}
                  className="doc-img"
                  alt=""
                />
              </div>
              <div className="col-12 col-md-4">
                <img
                  src={`data:image/jpeg;base64,${documentData?.dlFrontPhoto}`}
                  className="doc-img"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {documentData?.isDocVerified == true ? (
          ""
        ) : (
          <>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
            <Button color="success" onClick={() => handleAccept(documentData)}>
              Accept
            </Button>
            <Button color="danger" onClick={() => handleReject(documentData)}>
              Reject
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default DocumentModal;
