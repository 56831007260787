import Pagination from "react-js-pagination";
import "./pagination.css";

const PaginationComponent = ({
  currentPage,
  PageChangeHandler,
  totalPages,
}) => {
  const totalData = totalPages * 10;
  return (
    <>
      <div className="page-pagination">
        {totalPages > 1 && (
          <Pagination
            itemClass="mx-4"
            activePage={currentPage}
            itemsCountPerPage={10}
            totalItemsCount={totalData}
            pageRangeDisplayed={3}
            onChange={(page) => PageChangeHandler(page)}
          />
        )}
      </div>
    </>
  );
};
export default PaginationComponent;
