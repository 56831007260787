import { useEffect, useState } from "react";
import CommonTable from "../../Components/Common/CommonTable";
import MainHeading from "../../Components/Common/MainHeading";
import MainLayout from "../../Components/Layouts/MainLayout";
import { callApi } from "../../utility/useapi";
import { ApiUrl } from "../../utility/ApiUrls";
import CreateBannerModal from "./CreateBannerModal";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/ROUTES";

export default function Banner() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [BannerList, setBannerList] = useState([]);
  const [pageStates, setPageStates] = useState({
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });

  const toggleModal = () => setModalOpen(!modalOpen);
  const TableHeading = [
    {
      label: "#",
      value: "index",
    },
    {
      label: "Banner Name",
      value: "bannerName",
    },
    {
      label: "Type",
      value: "bannerType",
    },
    {
      label: "Start Date",
      value: "startDate",
    },
    {
      label: "End Date",
      value: "endDate",
    },
    {
      label: "Status",
      value: "  ",
    },
    // {
    //   label: "Status",
    //   title: "status",
    // },
  ];

  useEffect(() => {
    QRList();
  }, []);

  const QRList = () => {
    // ?filters=name%3Atest&searchQuery=test&pageNo=1&pageSize=10
    callApi(`${ApiUrl?.getBannerList}`).then((response) => {
      // console.log("Error...", response)
      setIsLoading(false);
      if (response?.status === true) {
        setBannerList(response?.data?.bannerData);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
      }
    });
  };

  const PageChangeHandler = (newPage) => {
    setPageStates((prevState) => ({
      ...prevState,
      currentPage: newPage,
      tableLoader: true,
    }));

    callApi(
      `${ApiUrl?.getBannerList}?pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({
        ...prevState,
        tableLoader: false,
      }));
      if (response?.status === true) {
        BannerList(response?.data?.batches);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
        // Handle errors
      }
    });
  };

  const ActionData = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",

      clickHandler: (data) => {
        navigate(ROUTES?.bannerDetials, { state: data });
        // handle clickHandler
      },
    },
    // {
    //   icon: <i class="fas fa-file"></i>,
    //   label: "Documents",
    //   action: () => {
    //     // handle action
    //   }
    // },
    // {
    //   icon: <i class="fas fa-trash-alt"></i>,
    //   label: "Deactivate",
    //   action: () => {
    //     // handle action
    //   }
    // },
  ];
  const HeadingButtons = [
    {
      label: "Create Banner",
      function: () => {
        toggleModal();
      },
    },
  ];
  return (
    <>
      {/* <MainLayout>
      <MainHeading title={"Banner Management"} HeadingButtons={HeadingButtons} />
      <CommonTable TableHeading={TableHeading} ActionData={ActionData} />
    </MainLayout> */}
      <>
        <MainLayout>
          <MainHeading
            title={"Banner Management"}
            HeadingButtons={HeadingButtons}
          />
          <CommonTable
            TableHeading={TableHeading}
            TableData={BannerList}
            ActionData={ActionData}
            currentPage={pageStates?.currentPage}
            totalPages={pageStates?.totalPages}
            PageChangeHandler={PageChangeHandler}
          />
        </MainLayout>
        <CreateBannerModal
          isOpen={modalOpen}
          toggle={toggleModal}
          TableList={BannerList}
        />
      </>
    </>
  );
}
