import React from "react";
import MainLayout from "../../Components/Layouts/MainLayout";
import MainHeading from "../../Components/Common/MainHeading";
import DetailCard from "../UserDetails/Components/DetailCard";
import { useLocation, useNavigate } from "react-router-dom";

function BannerDetials() {
  const location = useLocation();
  const navigate = useNavigate();
  const backButtonHandler = () => navigate(-1);
  return (
    <MainLayout>
      <MainHeading
        title={"Vehicle Detials"}
        backButtonHandler={backButtonHandler}
      />
      <DetailCard>
        <div className="card manage-user-list">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
              <h4 className="d-flex align-items-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle cx={10} cy={10} r={2} fill="#000000" />
                </svg>
                Basic Details
              </h4>
              <div>
                <p>
                  <span className="text-muted fs-5">Status :-</span>
                  {location?.state?.isActive == true ? (
                    <span className="badge bg-green-25 fw-normal rounded-pill">
                      Active
                    </span>
                  ) : (
                    <span className="badge bg-red-25 fw-normal rounded-pill">
                      Not Active
                    </span>
                  )}
                </p>
              </div>
            </div>
            <ul className="row list-unstyled">
              <li className="col-12  ">
                <img
                  src={`data:image/jpeg;base64,${location?.state?.bannerImage64}`}
                  alt=""
                  className="w-100 rounded-3 "
                  style={{ maxHeight: 400, objectFit: "contain" }}
                />
              </li>
            </ul>
            <ul className="row list-unstyled">
              <li className="col-12 col-md-6 ">
                <p>
                  <span className="text-muted fs-5 d-block">
                    Banner Name :-
                  </span>
                  {location?.state?.bannerName}
                </p>
              </li>
              <li className="col-12 col-md-6 ">
                <p>
                  <span className="text-muted fs-5 d-block">Type :-</span>
                  {location?.state?.bannerType}
                </p>
              </li>
              <li className="col-12 col-md-6 ">
                <p>
                  <span className="text-muted fs-5 d-block">Start Date :-</span>
                  {location?.state?.startDate}
                </p>
              </li>
              <li className="col-12 col-md-6 ">
                <p>
                  <span className="text-muted fs-5 d-block">End Date :-</span>
                  {location?.state?.endDate}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </DetailCard>
    </MainLayout>
  );
}

export default BannerDetials;
