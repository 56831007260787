export const ApiUrl = {
  login: "api/Auth/login",
  userList: "api/User/getUsersList",
  getGuestUsersList: "api/User/getGuestUsersList",
  CreateQr: "api/QR/GetQrExcel",
  getBetchList: "api/QR/batchList",
  getBetchExcel: "api/QR/getBatchExcel?batchID=",
  userDetail: "api/User/getUserDetails?userGuid=",
  getAllVehicle: "api/Vehicle/getVehicles",
  vehicalDetials: "api/Vehicle/getVehileByID?",
  RCVerifyAction: "api/Vehicle/RCVerifyAction",
  getDashboardData:
    "api/Dashboard/getDashboardData/?filters=userAndVehicleYear%3A2025%3BqrScanAnalyticsYear%3A2025",
  getUserDocuments: "api/User/getUserDl?userId=",
  acceptDocument: "api/User/docVarification?userId=",
  DeactivateUser: "api/User/userActivation",
  getComplationsLogs: "api/User/getUserQueryList",
  getCallLogs: "api/IVR/callLogs",
  getScannerActivity: "api/User/getGuestUsersList",
  createBanner: "api/Banner/createAppBanner",
  getBannerList: "api/Banner/getBannerList",
  getBlankSpot: "api/Blackspot/getBlackspot",
  getVehicalActivty: "api/ActivityLog/vehicle/",
  getUserActivity: "api/ActivityLog/user/",
  getVehicalActivity: "api/ActivityLog/vehicle/",
  updateBlackSpot: "api/Blackspot/updateBlackspotData",
  deleteBlackSpot: "api/Blackspot/deleteBlackspot?id=",
};
