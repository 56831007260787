import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import { useEffect, useState } from "react";
import { ApiUrl } from "../../utility/ApiUrls";
import { callApi } from "../../utility/useapi";
import DocumentModal from "../VerifiedUsers/DocumentModal";
import moment from "moment";
import { toast } from "react-toastify";
import { ROUTES } from "../../Routes/ROUTES";
import CommonTable from "../../Components/Common/CommonTable";
import MainHeading from "../../Components/Common/MainHeading";

export default function GuestUserDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [CallLogsData, setCallLogsData] = useState([]);
  const [ScannerData, setScannerData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchScannerParams, setSearchScannerParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page")) || 1;

  const [pageStates, setPageStates] = useState({
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });

  const [ScannerpageStates, setScannerPageStates] = useState({
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });
  const TableHeading = [
    {
      label: "#",
      value: "index",
    },
    {
      label: "Mobile No.",
      value: "phone",
    },
    {
      label: "Scan Date/Time",
      value: "scanDateTime",
    },
    {
      label: "Qr Details",
      value: "qrHexaKey",
    },

    // {
    //   label: "Status",
    //   value: "status",
    // },
  ];

  const ActionData = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",
      action: () => {
        // handle action
      },
    },
    // {
    //   icon: <i class="fas fa-file"></i>,
    //   label: "Documents",
    //   action: () => {
    //     // handle action
    //   },
    // },
    {
      icon: <i class="fas fa-trash-alt"></i>,
      label: "Deactivate",
      action: () => {
        // handle action
      },
    },
  ];

  const TableHeadingCallLogs = [
    {
      label: "#",
      value: "index",
    },
    {
      label: "Date & Time",
      value: "dateTime",
    },
    {
      label: "Event Type",
      value: "callStatus",
    },
    {
      label: "Caller/Reciver",
      value: "callType",
    },
    {
      label: "Call Duration",
      value: "duration",
    },
    {
      label: "Number",
      value: "callerNumber",
    },
    {
      label: "Recording",
      value: "recordingURL",
    },

    // {
    //   label: "Status",
    //   value: "status",
    // },
  ];

  const ActionDataCallLogs = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",
      action: () => {
        // handle action
      },
    },
    // {
    //   icon: <i class="fas fa-file"></i>,
    //   label: "Documents",
    //   action: () => {
    //     // handle action
    //   },
    // },
    {
      icon: <i class="fas fa-trash-alt"></i>,
      label: "Deactivate",
      action: () => {
        // handle action
      },
    },
  ];

  const callLogsPageHandler = (newPage) => {
    // setSearchParams({ page: newPage }); // Update URL with the new page number
    setPageStates((prevState) => ({
      ...prevState,
      currentPage: 1,
      tableLoader: true,
    }));

    callApi(
      `${ApiUrl?.getCallLogs}?filters=searchQuery:${location?.state?.phone}&pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({
        ...prevState,
        tableLoader: false,
      }));
      if (response?.status === true) {
        setCallLogsData(response?.data?.logs);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
        toast.error("Failed to fetch call logs.");
      }
    });
  };

  useEffect(() => {
    callLogsPageHandler(initialPage); // Fetch data for the current page on initial load
  }, [initialPage]);

  const ScannerActivityPageHandler = (newPage) => {
    // setSearchScannerParams({ page: newPage }); // Update URL with the new page number
    setScannerPageStates((prevState) => ({
      ...prevState,
      currentPage: newPage,
      tableLoader: true,
    }));

    callApi(
      `${ApiUrl?.getScannerActivity}?filters=searchQuery:${location?.state?.phone}&pageNo=1&pageSize=10`
    ).then((response) => {
      //  setIsLoading(false);
      if (response?.status === true) {
        setScannerData(response?.data?.user); // Assuming the response contains document data
      } else {
        toast.error("Failed to fetch document data.");
      }
    });

    // callApi(
    //   `${
    //     ApiUrl?.getCallLogs
    //   }?filter=searchQuery:${""}&pageNo=${newPage}&pageSize=${
    //     pageStates?.pageSize
    //   }`
    // ).then((response) => {
    //   setPageStates((prevState) => ({
    //     ...prevState,
    //     tableLoader: false,
    //   }));
    //   if (response?.status === true) {
    //     setCallLogsData(response?.data?.logs);
    //     setPageStates((prevState) => ({
    //       ...prevState,
    //       count: response?.data?.totalData,
    //       totalPages: response?.data?.totalPages,
    //     }));
    //   } else {
    //     toast.error("Failed to fetch call logs.");
    //   }
    // });
  };

  useEffect(() => {
    ScannerActivityPageHandler(initialPage); // Fetch data for the current page on initial load
  }, [initialPage]);

  // Fetch User Data

  const scanerActivity = () => {
    //  setIsLoading(true);
    // /api/User/getGuestUsersList?filters=searchQuery%3A94&pageNo=1&pageSize=10
  };

  const backButtonHandler = () => navigate(-1);

  return (
    <MainLayout>
      <div className="content-head mb-5 d-flex align-items-start justify-content-between">
        <MainHeading
          title={"Guest User Detail"}
          backButtonHandler={backButtonHandler}
        />
      </div>
      <div>
        <nav>
          <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
            <button
              className="btn btn-tabs px-5 rounded-0 active"
              data-bs-toggle="tab"
              data-bs-target="#tab-two"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="true"
            >
              QR Scanner Activity
            </button>
            <button
              className="btn btn-tabs px-5 rounded-0"
              data-bs-toggle="tab"
              data-bs-target="#tab-three"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="true"
            >
              Call Logs
            </button>
          </div>
        </nav>
      </div>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="tab-two"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabIndex={0}
        >
          <div className="card manage-user-list">
            <CommonTable
              TableHeading={TableHeading}
              TableData={ScannerData}
              ActionData={ActionData}
              currentPage={pageStates?.currentPage}
              totalPages={pageStates?.totalPages}
              PageChangeHandler={ScannerActivityPageHandler}
            />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="tab-three"
          role="tabpanel"
          tabIndex={0}
        >
          <CommonTable
            TableHeading={TableHeadingCallLogs}
            TableData={CallLogsData}
            // ActionData={ActionDataCallLogs}
            currentPage={pageStates?.currentPage}
            totalPages={pageStates?.totalPages}
            PageChangeHandler={callLogsPageHandler}
          />

          <DocumentModal
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            documentData={documentData}
            // handleAccept={handleAccept}
            // handleReject={handleReject}
          />
        </div>
      </div>
    </MainLayout>
  );
}
