import Header from "../Common/Header";
import Sidebar from "../Common/Sidebar";

export default function MainLayout({ children }) {
  return (<>
    <div class="main-wrapper position-relative">
      <Header />
      <div class="main-content-wrapper">
        <Sidebar />
        <div class="main-view-content" id="contentWrapper">
          <div class="content container-fluid">
            {children}
          </div>
        </div>
      </div>
    </div>
  </>)
}