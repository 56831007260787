import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { callApi } from "../../utility/useapi";
import { ApiUrl } from "../../utility/ApiUrls";

const CreateBannerModal = (props) => {
  const [bannerName, setBannerName] = useState("");
  const [bannerType, setBannerType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bannerImage64, setBannerImage64] = useState("");
  const [bannerImagePreview, setBannerImagePreview] = useState(""); // For image preview

  const handleFormSubmit = async () => {
    // Validate fields
    if (!bannerName) {
      toast.error("Please enter a banner name", { position: "top-right" });
      return;
    }
    if (!bannerType) {
      toast.error("Please enter a banner type", { position: "top-right" });
      return;
    }
    if (!startDate) {
      toast.error("Please select a start date", { position: "top-right" });
      return;
    }
    if (!endDate) {
      toast.error("Please select an end date", { position: "top-right" });
      return;
    }
    if (!bannerImage64) {
      toast.error("Please upload a banner image", { position: "top-right" });
      return;
    }

    const payload = {
      // createdAt: new Date().toISOString(),
      // bannerName,
      // bannerType,
      // startDate,
      // endDate,
      // bannerImage64,

      BannerName: bannerName,
      BannerType: bannerType,
      StartDate: startDate,
      EndDate: endDate,
      BannerImage64: bannerImage64,
      isActive: true,
    };

    try {
      const response = await callApi(ApiUrl?.createBanner, {
        method: "POST",
        data: payload,
      });

      if (response?.status === true) {
        toast.success(response.message || "Banner created successfully!", {
          position: "top-right",
        });
        setBannerName("");
        setBannerType("");
        setStartDate("");
        setEndDate("");
        setBannerImage64("");
        setBannerImagePreview("");
        props.toggle();
      } else {
        toast.error(response?.message || "Failed to create banner.", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error while creating banner:", error);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1]; // Extract Base64
      setBannerImage64(base64String); // Set Base64 for API
      setBannerImagePreview(reader.result); // Set preview URL
    };

    reader.readAsDataURL(file); // Convert file to Base64 and preview
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg" centered>
      <ModalHeader toggle={props.toggle}>
        <h1 className="modal-title fs-3 rajdhani-semibold">
          Create New Banner
        </h1>
      </ModalHeader>
      <div className="modal-content rounded-0 shadow-md border-0">
        <div className="modal-body p-4">
          <form>
            <div className="card shadow-0 bg-transparent mb-0">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        Banner Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Banner Name"
                        value={bannerName}
                        onChange={(e) => setBannerName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        Banner Type <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Banner Type"
                        value={bannerType}
                        onChange={(e) => setBannerType(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        Start Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        End Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        Banner Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        accept="image/*"
                        onChange={handleImageUpload}
                      />
                      {bannerImagePreview && (
                        <div className="mt-3">
                          <img
                            src={bannerImagePreview}
                            alt="Banner Preview"
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              border: "1px solid #ddd",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer p-4">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={props.toggle}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleFormSubmit}
          >
            Create Banner
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateBannerModal;
