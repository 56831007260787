export default function CardFilter() {
  return (
    <div class="dropdown align-self-start ms-auto">
      <button
        class="btn dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="fas fa-ellipsis-v text-primary"></i>
      </button>
      <ul class="dropdown-menu shadow">
        <li>
          <a class="dropdown-item" href="javascript:void(0);">
            Today</a>
        </li>
        <li>
          <a class="dropdown-item" href="javascript:void(0);">
            Week</a>
        </li>
        <li>
          <a class="dropdown-item" href="javascript:void(0);">
            Month</a>
        </li>
        <li>
          <a class="dropdown-item" href="javascript:void(0);">
            Year</a>
        </li>
        <li>
          <div class="input-group mx-3">
            <input
              type="date"
              class="form-control"
              placeholder="Start"
              aria-label="startDate"
            />
            <input
              type="date"
              class="form-control"
              placeholder="End"
              aria-label="endDate"
            />
            <button class="btn btn-primary" type="button">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
  )
}