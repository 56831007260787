import { useEffect, useState } from "react";
import CommonTable from "../../Components/Common/CommonTable";
import MainHeading from "../../Components/Common/MainHeading";
import MainLayout from "../../Components/Layouts/MainLayout";
import { callApi } from "../../utility/useapi";
import { ApiUrl } from "../../utility/ApiUrls";
import { ROUTES } from "../../Routes/ROUTES";
import { useNavigate } from "react-router-dom";

export default function UnverifiedUsers() {
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const navigate = useNavigate();
  const [pageStates, setPageStates] = useState({
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });
  const TableHeading = [
    {
      label: "#",
      value: "index",
    },
    {
      label: "Mobile No.",
      value: "phone",
    },
    {
      label: "Scan Date/Time",
      value: "scanDateTime",
    },
    {
      label: "Qr Details",
      value: "qrHexaKey",
    },

    // {
    //   label: "Status",
    //   value: "status",
    // },
  ];

  const ActionData = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",
      clickHandler: (data) => {
        navigate(ROUTES?.guestUserDetials, { state: data });
        // handle clickHandler
      },
    },
    // {
    //   icon: <i class="fas fa-file"></i>,
    //   label: "Documents",
    //   action: () => {
    //     // handle action
    //   },
    // },
    {
      icon: <i class="fas fa-trash-alt"></i>,
      label: "Deactivate",
      action: () => {
        // handle action
      },
    },
  ];

  useEffect(() => {
    userList();
  }, []);

  const userList = () => {
    // ?filters=name%3Atest&searchQuery=test&pageNo=1&pageSize=10
    callApi(`${ApiUrl?.getGuestUsersList}`).then((response) => {
      // console.log("Error...", response)
      setIsLoading(false);
      if (response?.status === true) {
        setUsersList(response?.data?.user);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
      }
    });
  };

  const PageChangeHandler = (newPage) => {
    setPageStates((prevState) => ({
      ...prevState,
      currentPage: newPage,
      tableLoader: true,
    }));

    callApi(
      `${ApiUrl?.getGuestUsersList}?pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({
        ...prevState,
        tableLoader: false,
      }));
      if (response?.status === true) {
        setUsersList(response?.data?.user);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
        // Handle errors
      }
    });
  };

  const fetchTableData = (searchQuery = "", newPage = 1) => {
    setPageStates((prevState) => ({ ...prevState, tableLoader: true }));

    callApi(
      `${ApiUrl?.userList}?filters=searchQuery:${searchQuery?.search}&pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({ ...prevState, tableLoader: false }));

      if (response?.status === true) {
        setUsersList(response?.data?.user || []); // Default to empty array if no users
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData || 0,
          totalPages: response?.data?.totalPages || 1,
        }));
      } else {
        setUsersList([]); // Clear table data on error or empty response
        setPageStates((prevState) => ({
          ...prevState,
          count: 0,
          totalPages: 1,
        }));
        console.error("Error fetching data:", response?.message);
      }
    });
  };

  return (
    <>
      <MainLayout>
        <MainHeading title={"Unverified User"} />
        <CommonTable
          TableHeading={TableHeading}
          TableData={usersList}
          ActionData={ActionData}
          currentPage={pageStates?.currentPage}
          totalPages={pageStates?.totalPages}
          PageChangeHandler={PageChangeHandler}
          fetchTableData={fetchTableData} // Pass to handle search
        />
      </MainLayout>
    </>
  );
}
