import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { loadState } from "../utility/localstorage";
import userReducers from "./reducers/userReducer";


const persistedState = loadState();
const rootReducer = {
  root: userReducers,
};
const mfunction = () => [thunk];

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: mfunction,

});
export default store;
