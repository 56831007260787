import { useEffect, useState } from "react";
import CommonTable from "../../Components/Common/CommonTable";
import MainHeading from "../../Components/Common/MainHeading";
import MainLayout from "../../Components/Layouts/MainLayout";
import { callApi } from "../../utility/useapi";
import { ApiUrl } from "../../utility/ApiUrls";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/ROUTES";
import EditLocationModal from "./EditBlackSpotModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../utility/ConfirmationModal";

export default function BlackSpot() {
  const [isLoading, setIsLoading] = useState(false);
  const [BlackSpot, setBlackSpot] = useState([]);
  const [pageStates, setPageStates] = useState({
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });

  const [modal, setModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedDeleteData, setSelectedDeleteData] = useState(null); // Track data for delete

  const toggleModal = () => setModal(!modal);

  const handleSave = (updatedData) => {
    const payload = {
      id: updatedData?.id,
      isVerified: updatedData?.isVerified,
      narration: updatedData?.narration,
      city: updatedData?.city,
      state: updatedData?.state,
    };

    const bodyOption = {
      method: "POST",
      data: payload,
    };

    callApi(`${ApiUrl?.updateBlackSpot}`, bodyOption).then((response) => {
      setIsLoading(false);
      if (response?.status === true) {
        toast.success(response.status);
        QRList();
      } else {
        toast.error(response);
      }
    });
    toggleModal();
  };

  const TableHeading = [
    { label: "#", value: "index" },
    { label: "Id", value: "id" },
    { label: "Address", value: "narration" },
    { label: "Latitude", value: "latitude" },
    { label: "Longitude", value: "longitude" },
    { label: "Created/Updated", value: "createdAt" },
    { label: "State", value: "state" },
    { label: "City", value: "city" },
  ];

  useEffect(() => {
    QRList();
  }, []);

  const QRList = () => {
    callApi(`${ApiUrl?.getBlankSpot}`).then((response) => {
      setIsLoading(false);
      if (response?.status === true) {
        setBlackSpot(response?.data?.blackspotData);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      }
    });
  };

  const PageChangeHandler = (newPage) => {
    setPageStates((prevState) => ({
      ...prevState,
      currentPage: newPage,
      tableLoader: true,
    }));

    callApi(
      `${ApiUrl?.getBlankSpot}?pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({
        ...prevState,
        tableLoader: false,
      }));
      if (response?.status === true) {
        setBlackSpot(response?.data?.blackspotData);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      }
    });
  };

  const ActionData = [
    {
      icon: <i className="fas fa-file"></i>,
      label: "Edit",
      clickHandler: (data) => {
        setSelectedData(data);
        toggleModal();
      },
    },
    {
      icon: <i className="fas fa-trash-alt"></i>,
      label: "Delete",
      clickHandler: (data) => {
        setSelectedDeleteData(data); // Set the selected data for deletion
        toggleConfirmationModal();
      },
    },
  ];

  const HeadingButtons = [];

  const fetchTableData = (searchQuery = "", newPage = 1) => {
    setPageStates((prevState) => ({ ...prevState, tableLoader: true }));

    callApi(
      `${ApiUrl?.getBlankSpot}?filters=searchQuery:${searchQuery?.search}&pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({ ...prevState, tableLoader: false }));

      if (response?.status === true) {
        setBlackSpot(response?.data?.blackspotData || []);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData || 0,
          totalPages: response?.data?.totalPages || 1,
        }));
      } else {
        setBlackSpot([]);
        setPageStates((prevState) => ({
          ...prevState,
          count: 0,
          totalPages: 1,
        }));
      }
    });
  };

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const toggleConfirmationModal = () => {
    setIsConfirmationOpen(!isConfirmationOpen);
  };

  const deleteBlackSpot = () => {
    if (!selectedDeleteData?.id) return;

    callApi(`${ApiUrl?.deleteBlackSpot}${selectedDeleteData.id}`, {
      method: "DELETE",
    }).then((response) => {
      setIsLoading(false);
      if (response?.status === true) {
        toast.success("Black spot deleted successfully");
        QRList();
      } else {
        toast.error("Failed to delete the black spot");
      }
      toggleConfirmationModal();
    });
  };

  return (
    <>
      <MainLayout>
        <MainHeading title={"Black Spots"} HeadingButtons={HeadingButtons} />
        <CommonTable
          TableHeading={TableHeading}
          TableData={BlackSpot}
          ActionData={ActionData}
          currentPage={pageStates?.currentPage}
          totalPages={pageStates?.totalPages}
          PageChangeHandler={PageChangeHandler}
          fetchTableData={fetchTableData}
        />

        <EditLocationModal
          isOpen={modal}
          toggle={toggleModal}
          data={selectedData}
          onSave={handleSave}
        />

        <ConfirmationModal
          isOpen={isConfirmationOpen}
          toggle={toggleConfirmationModal}
          onConfirm={deleteBlackSpot}
          message={`Are you sure you want to delete this black spot with ID: ${selectedDeleteData?.id}?`}
        />
      </MainLayout>
    </>
  );
}
