import moment from "moment/moment";
import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";

const ActivityModal = ({ isOpen, toggleActivity, activityData }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleActivity} size="lg">
      <ModalHeader toggle={toggleActivity}>Activity Log</ModalHeader>
      <ModalBody>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Activity</th>
              <th>Platform</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {activityData && activityData?.length == 0
              ? "No data found"
              : activityData.map((res, index) => {
                  return (
                    <tr>
                      <td>{index}</td>
                      <td>{res?.action}</td>
                      <td>{res?.platform}</td>
                      <td>{moment(res?.logAddedAt).format("ll")}</td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleActivity}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ActivityModal;
