import { isAction } from "@reduxjs/toolkit";
import PaginationComponent from "./Pagination";
import moment from "moment";
import { useState } from "react";

export default function CommonTable({
  TableHeading,
  TableData,
  isLoading,
  currentPage,
  count,
  totalPages,
  PageChangeHandler,
  ActionData,
  handleStatusToggle,
  fetchTableData,
}) {
  const [searchQuery, setSearchQuery] = useState("");

  const [isDocVerifiedFilter, setIsDocVerifiedFilter] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");

  // Handle search query changes
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Reset to page 1 and fetch filtered data
    fetchTableData({ search: query, page: 1 });
  };

  // Handle "Filter By" dropdown changes
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter !== "isDocVerified") {
      setIsDocVerifiedFilter(""); // Reset the "Is Document Verified" filter if not selected
      fetchTableData({ search: searchQuery, page: 1 }); // Fetch data without the filter
    }
  };

  // Handle "Is Document Verified" filter changes
  const handleDocVerifiedFilterChange = (event) => {
    const filterValue = event.target.value; // 'true', 'false', or ''
    setIsDocVerifiedFilter(filterValue);
    fetchTableData({
      search: searchQuery,
      page: 1,
      isDocVerified: filterValue,
    });
  };

  const renderTableData = (heading, data, index) => {
    switch (heading.value) {
      case "index":
        return (
          <td>
            {((currentPage - 1) * 10 + index + 1).toString().padStart(2, "0")}
          </td>
        );
      case "toggleStatus":
        return (
          <td>
            <div className="form-check form-switch d-flex justify-content-center">
              <input
                type="checkbox"
                className="form-check-input rounded-4"
                checked={data[heading.value]}
                onChange={() =>
                  handleStatusToggle(data?.id, data[heading.value])
                }
              />
            </div>
          </td>
        );
      case "isDocVerified":
        let verifyStatus;

        if (data.isDocVerified === true) {
          verifyStatus = (
            <td>
              <span className="badge bg-success fw-normal rounded-pill badge fw-normal rounded-pill">
                Yes
              </span>
            </td>
          );
        } else {
          verifyStatus = (
            <td>
              <span className="badge bg-danger fw-normal rounded-pill badge fw-normal rounded-pill">
                No
              </span>
            </td>
          );
        }
        return verifyStatus;

      case "status":
        const { statusText, smallStatusText, id } = data[heading.value] || {};

        const badgeClass =
          id == 1 ? "bg-warning" : id === 3 ? "bg-success" : "bg-danger";
        return (
          <td key={index}>
            <span
              className={`badge   ${badgeClass} fw-normal rounded-pill badge fw-normal rounded-pill`}
            >
              {statusText || smallStatusText}
            </span>
          </td>
        );
      case "isVerified":
        let isVerified;

        if (data.isVerified === true) {
          isVerified = (
            <td>
              <span className="badge bg-success fw-normal rounded-pill badge fw-normal rounded-pill">
                Yes
              </span>
            </td>
          );
        } else {
          isVerified = (
            <td>
              <span className="badge bg-danger fw-normal rounded-pill badge fw-normal rounded-pill">
                No
              </span>
            </td>
          );
        }
        return isVerified;

      case "category":
        let category;
        if (data[heading.key] === 0) {
          category = (
            <td>
              <span className="badge bg-green fw-normal rounded-pill">car</span>
            </td>
          );
        } else {
          category = (
            <td>
              <span className="badge bg-danger fw-normal rounded-pill">No</span>
            </td>
          );
        }
        return category;
      case "isActive":
        if (data.isActive) {
          let isActive;
          if (data.isActive === true) {
            isActive = (
              <td>
                <span className="badge bg-success fw-normal rounded-pill badge fw-normal rounded-pill">
                  Active
                </span>
              </td>
            );
          } else {
            isActive = (
              <td>
                <span className="badge bg-danger fw-normal rounded-pill badge fw-normal rounded-pill">
                  Deactive
                </span>
              </td>
            );
          }
          return isActive;
        } else {
          console.error("Invalid data or isActive property is undefined");
          return null; // or a default placeholder
        }
        return isAction;
      case "createdAt":
        let creationDate;

        if (data.createdAt) {
          const formattedDate = new Date(data.createdAt).toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          );
          creationDate = (
            <td>
              <span className="fw-normal">{formattedDate}</span>
            </td>
          );
        } else {
          creationDate = (
            <td>
              <span className="text-muted">N/A</span>
            </td>
          );
        }
        return creationDate;
      case "scanDateTime":
        let scanDateTime;

        if (data.createdAt) {
          const formattedDate = moment(data.createdAt).format("LLL");
          scanDateTime = (
            <td>
              <span className="fw-normal">{formattedDate}</span>
            </td>
          );
        } else {
          scanDateTime = (
            <td>
              <span className="text-muted">N/A</span>
            </td>
          );
        }
        return scanDateTime;
      case "recordingURL":
        let recordingURL;

        if (data.recordingURL == "No Voice") {
          recordingURL = <td>No Voice</td>;
        } else {
          recordingURL = (
            <td>
              <audio controls>
                <source src={data?.recordingURL} type="audio/ogg" />
                <source src={data?.recordingURL} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              {data?.id}
            </td>
          );
        }
        return recordingURL;

      default:
        return <td className="text-wrap">{data[heading.value] || "--"}</td>;
    }
  };

  return (
    <div className="card manage-user-list">
      <div className="card-body">
        <div className="manage-table main-table">
          <div className="table-head">
            <div className="position-relative">
              <span className="search-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.7499 16.75L13.4874 13.4875M15.25 9.25C15.25 12.5637 12.5637 15.25 9.25 15.25C5.93629 15.25 3.25 12.5637 3.25 9.25C3.25 5.93629 5.93629 3.25 9.25 3.25C12.5637 3.25 15.25 5.93629 15.25 9.25Z"
                    stroke="#818898"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                className="form-control search-control"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            {/* Conditionally Render "Is Document Verified" Filter */}
            {selectedFilter === "isDocVerified" && (
              <div className="filter mt-3">
                <select
                  className="form-select"
                  value={isDocVerifiedFilter}
                  onChange={handleDocVerifiedFilterChange}
                >
                  <option value="">All</option>
                  <option value="true">Verified</option>
                  <option value="false">Not Verified</option>
                </select>
              </div>
            )}

            <div className="sorted d-flex align-items-center justify-content-end gap-3">
              <h5>Filter By:</h5>
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedFilter || "Select Filter"}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922"
                        stroke="#36394A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleFilterChange("isDocVerified")}
                    >
                      Is Document Verified
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleFilterChange("name")}
                    >
                      Name
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => handleFilterChange("date")}
                    >
                      Date
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <table className="table table-border-bottom align-middle nowrap  w-100">
            <thead>
              <tr>
                {TableHeading?.map((data, index) => (
                  <th
                    key={index}
                    className="text-muted text-small text-uppercase  text-nowrap"
                  >
                    {data?.label}
                  </th>
                ))}
                {ActionData && (
                  <th className="text-muted text-small text-uppercase  text-nowrap">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody className={isLoading ? "loading-skeleton" : ""}>
              {isLoading ? (
                <>
                  {[1, 2, 3].map((item, index) => (
                    <tr key={index}>
                      {TableHeading &&
                        TableHeading?.length > 0 &&
                        [...TableHeading]?.map((action, index) => (
                          <td key={index}>
                            <div className="">demo</div>
                          </td>
                        ))}
                      {ActionData?.length > 0 && (
                        <td>
                          <div className="">demo</div>
                        </td>
                      )}
                    </tr>
                  ))}
                </>
              ) : TableData && TableData?.length > 0 ? (
                TableData?.map((data, index) => {
                  return (
                    <tr>
                      {TableHeading?.map((heading) =>
                        renderTableData(heading, data, index)
                      )}
                      <td>
                        <div className="text-center">
                          {ActionData && (
                            <div className="dropdown d-inline-block">
                              <button
                                class="btn btn-white dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fas fa-ellipsis-v fs-14 text-primary"></i>
                              </button>
                              <ul className="dropdown-menu shadow-lg">
                                {ActionData?.length > 0 &&
                                  ActionData?.map((action, index) => {
                                    return (
                                      <li key={index}>
                                        <a
                                          className="btn btn-white dropdown-toggle"
                                          role="button"
                                          onClick={() =>
                                            action?.clickHandler(data)
                                          } // Pass `data` here
                                        >
                                          {action?.icon}
                                          {action?.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="">
                  <td colSpan={18} className="text-center fw-medium">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="d-flex justify-content-between align-items-center paggination-nav">
              {count > 0 && !isLoading && (
                <p className="mb-0 text-muted fs-6 text-uppercase">
                  {`Showing ${currentPage * 10 - 9} to ${
                    currentPage * 10 < count ? currentPage * 10 : count
                  } of ${count} entries`}
                </p>
              )}

              <PaginationComponent
                currentPage={currentPage}
                PageChangeHandler={PageChangeHandler}
                totalPages={totalPages}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
