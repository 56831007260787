import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { toast } from "react-toastify"; // Assuming toast is from react-toastify
import { callApi } from "../../utility/useapi";
import { ApiUrl } from "../../utility/ApiUrls";

const CreateQrModal = (props) => {
  // States for input fields
  const [service, setService] = useState(""); // For dropdown
  const [numberOfQRCodes, setNumberOfQRCodes] = useState(""); // For input field

  const handleFormSubmit = () => {
    // Validate fields
    if (!service) {
      toast.error("Please select a service", { position: "top-right" });
      return;
    }
    if (!numberOfQRCodes || isNaN(numberOfQRCodes)) {
      toast.error("Please enter a valid number of QR Codes", {
        position: "top-right",
      });
      return;
    }

    // Prepare payload
    const payload = {
      batchQRQuantity: parseInt(numberOfQRCodes, 10), // Convert to number
      //   qrBaseUrl: "https://qr.viqr.in/",
      category: parseInt(service, 10), // Convert to number
    };

    const bodyOption = {
      method: "POST",
      data: payload,
    };

    // API Call
    callApi(ApiUrl?.CreateQr, bodyOption).then((response) => {
      if (response?.status === true) {
        toast.success(response.message, { position: "top-right" });
      } else {
        toast.error(response?.message, { position: "top-right" });
      }
    });
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg" centered>
      <ModalHeader toggle={props.toggle}>
        <h1
          className="modal-title fs-3 rajdhani-semibold"
          id="addBranchModalLabel"
        >
          Generate QR Code
        </h1>
      </ModalHeader>
      <div className="modal-content rounded-0 shadow-md rounded-0 border-0 shadow-lg">
        <div className="modal-body p-4">
          <form action="">
            <div className="card shadow-0 bg-transparent mb-0">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label htmlFor="material" className="form-label">
                        Use Service For <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control form-select"
                        aria-label="Default select example"
                        value={service} // Bind value to state
                        onChange={(e) => setService(e.target.value)} // Update state
                      >
                        <option className="d-none" value="">
                          Select Service
                        </option>
                        <option value={0}>Car</option>
                        <option value={2}>Bike</option>
                        <option value={1}>Bus</option>
                        <option value={3}>Taxi</option>
                        <option value={4}>Truck</option>
                      </select>
                      <div className="form-text text-danger d-none">
                        This field is Required
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label htmlFor="qrnum" className="form-label">
                        Number Of QR Code
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Number Of QR Code"
                        id="qrnum"
                        value={numberOfQRCodes} // Bind value to state
                        onChange={(e) => setNumberOfQRCodes(e.target.value)} // Update state
                      />
                      <div className="form-text text-danger d-none">
                        This field is Required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer p-4">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={props.toggle} // Close modal
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleFormSubmit} // Submit form
          >
            Generate
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateQrModal;
