export const ROUTES = {
  login_route: "/",
  dashboard: "/dashboard",
  verifiedUsers: "/verified-users",
  UnverifiedUsers: "/Unverified-users",
  category: "/category",
  generateQR: "/generate-qr",
  complaints: "/complaints",
  banner: "/banner",
  userDetails: "/user-details",
  vehicle: "/vehicle",
  vehicleDetials: "/vahicle-detials",
  guestUserDetials: "/GuestUserDetails",
  bannerDetials: "/banner-detials",
  blackSpot: "/black-spot",
};
