import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationModal = ({ isOpen, toggle, onConfirm, message, data }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Action</ModalHeader>
      <ModalBody>{message || "Are you sure you want to proceed?"}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => onConfirm(data)}>
          Yes, Delete
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
