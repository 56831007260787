export default function Header() {
  return (<>
    <header class="header fixed-top">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <a class="navbar-brand p-0" id="brand" href="javascript:void(0);">
            <img
              class="main-logo"
              src="../assets/img/logo.png"
              alt="VIQR logo"
            />
          </a>
          <div
            class="collapse navbar-collapse justify-content-between align-items-stretch ms-5 border-start ps-5"
          >
            <button
              class="btn btn-toggle border-0 d-none"
              id="toggleButton"
              type="button"
            >
              <i class="fas fa-bars"></i>
            </button>

            <div
              class="header-nav d-flex align-items-stretch justify-content-end w-100"
            >
              <ul class="d-flex align-items-center gap-3 list-unstyled mb-0">
                <li>
                  <button class="btn" type="button">
                    <i class="far fa-bell"></i>
                  </button>
                  {/* <!-- =========================== -->
                  <!-- this is a notification dropdown , toggle active class on it  -->
                  <!-- =========================== --> */}
                  <div class="notification-dropbox shadow">
                    <div class="header">
                      <h3 class="m-0">Notifications</h3>
                      <button class="btn btn-close p-0"></button>
                    </div>
                    <div class="body">
                      <ul class="list-unstyled">
                        <li class="d-flex gap-3">
                          <span class="icon"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="16"
                            height="16"
                            x="0"
                            y="0"
                            viewBox="0 0 682.667 682.667"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            xmlSpace="preserve"
                          >
                              <g>
                                <defs>
                                  <clipPath
                                    id="a"
                                    clipPathUnits="userSpaceOnUse"
                                  >
                                    <path
                                      d="M0 512h512V0H0Z"
                                      fill="#000000"
                                      opacity="1"
                                      data-original="#000000"
                                    ></path>
                                  </clipPath>
                                </defs>
                                <path
                                  d="M0 0h208.869"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1"
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 224.087)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1"
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 315.984)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1"
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 407.883)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <g
                                  clip-path="url(#a)"
                                  transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
                                >
                                  <path
                                    d="M0 0v8.891c0 31.975-25.922 57.897-57.898 57.897-31.976 0-57.898-25.922-57.898-57.897V0c0-34.55-15.647-67.199-42.481-88.84a10.024 10.024 0 0 1-2.65-3.228c-3.361-6.568 1.409-14.367 8.787-14.367H36.344c7.378 0 12.147 7.799 8.787 14.367a10.024 10.024 0 0 1-2.65 3.228A114.135 114.135 0 0 0 0 0Z"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(458.272 380.14)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0h-260.793c-29.002 0-52.513-23.511-52.513-52.513V-257.5c0-29.001 23.511-52.513 52.513-52.513h4.969c4.543 0 7.979-4.11 7.174-8.581l-12.939-71.841c-.973-5.402 5.522-8.907 9.503-5.127l85.195 80.873a17.024 17.024 0 0 0 11.719 4.676H72.28c29.002 0 52.513 23.512 52.513 52.513v64.185"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(320.806 428.723)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0v14.959c0 12.998-10.536 23.534-23.533 23.534h-.001c-12.997 0-23.533-10.536-23.533-23.534V0"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(423.908 441.928)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0c3.582-19.109 20.343-33.574 40.492-33.574 20.149 0 36.909 14.465 40.491 33.574"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(359.883 273.706)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                </g>
                              </g></svg
                            ></span>
                          <div class="d-flex flex-column">
                            <p>
                              Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Laboriosam dicta consequuntur
                              harum.
                            </p>
                            <div class="d-flex align-items-center justify-content-between">
                              <span class="time">Monday,7:01 AM</span>
                              <span class="date">Nov 29,2024</span>

                            </div>
                          </div>
                        </li>
                        <li class="d-flex gap-3">
                          <span class="icon"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="16"
                            height="16"
                            x="0"
                            y="0"
                            viewBox="0 0 682.667 682.667"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            xmlSpace="preserve"
                          >
                              <g>
                                <defs>
                                  <clipPath
                                    id="a"
                                    clipPathUnits="userSpaceOnUse"
                                  >
                                    <path
                                      d="M0 512h512V0H0Z"
                                      fill="#000000"
                                      opacity="1"
                                      data-original="#000000"
                                    ></path>
                                  </clipPath>
                                </defs>
                                <path
                                  d="M0 0h208.869"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 224.087)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 315.984)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 407.883)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <g
                                  clip-path="url(#a)"
                                  transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
                                >
                                  <path
                                    d="M0 0v8.891c0 31.975-25.922 57.897-57.898 57.897-31.976 0-57.898-25.922-57.898-57.897V0c0-34.55-15.647-67.199-42.481-88.84a10.024 10.024 0 0 1-2.65-3.228c-3.361-6.568 1.409-14.367 8.787-14.367H36.344c7.378 0 12.147 7.799 8.787 14.367a10.024 10.024 0 0 1-2.65 3.228A114.135 114.135 0 0 0 0 0Z"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(458.272 380.14)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0h-260.793c-29.002 0-52.513-23.511-52.513-52.513V-257.5c0-29.001 23.511-52.513 52.513-52.513h4.969c4.543 0 7.979-4.11 7.174-8.581l-12.939-71.841c-.973-5.402 5.522-8.907 9.503-5.127l85.195 80.873a17.024 17.024 0 0 0 11.719 4.676H72.28c29.002 0 52.513 23.512 52.513 52.513v64.185"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(320.806 428.723)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0v14.959c0 12.998-10.536 23.534-23.533 23.534h-.001c-12.997 0-23.533-10.536-23.533-23.534V0"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(423.908 441.928)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0c3.582-19.109 20.343-33.574 40.492-33.574 20.149 0 36.909 14.465 40.491 33.574"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(359.883 273.706)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                </g>
                              </g></svg
                            ></span>
                          <div class="d-flex flex-column">
                            <p>
                              Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Laboriosam dicta consequuntur
                              harum.
                            </p>
                            <div class="d-flex align-items-center justify-content-between">
                              <span class="time">Monday,7:01 AM</span>
                              <span class="date">Nov 29,2024</span>

                            </div>
                          </div>
                        </li>
                        <li class="d-flex gap-3">
                          <span class="icon"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="16"
                            height="16"
                            x="0"
                            y="0"
                            viewBox="0 0 682.667 682.667"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            xmlSpace="preserve"
                          >
                              <g>
                                <defs>
                                  <clipPath
                                    id="a"
                                    clipPathUnits="userSpaceOnUse"
                                  >
                                    <path
                                      d="M0 512h512V0H0Z"
                                      fill="#000000"
                                      opacity="1"
                                      data-original="#000000"
                                    ></path>
                                  </clipPath>
                                </defs>
                                <path
                                  d="M0 0h208.869"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 224.087)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 315.984)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 407.883)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <g
                                  clip-path="url(#a)"
                                  transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
                                >
                                  <path
                                    d="M0 0v8.891c0 31.975-25.922 57.897-57.898 57.897-31.976 0-57.898-25.922-57.898-57.897V0c0-34.55-15.647-67.199-42.481-88.84a10.024 10.024 0 0 1-2.65-3.228c-3.361-6.568 1.409-14.367 8.787-14.367H36.344c7.378 0 12.147 7.799 8.787 14.367a10.024 10.024 0 0 1-2.65 3.228A114.135 114.135 0 0 0 0 0Z"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(458.272 380.14)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0h-260.793c-29.002 0-52.513-23.511-52.513-52.513V-257.5c0-29.001 23.511-52.513 52.513-52.513h4.969c4.543 0 7.979-4.11 7.174-8.581l-12.939-71.841c-.973-5.402 5.522-8.907 9.503-5.127l85.195 80.873a17.024 17.024 0 0 0 11.719 4.676H72.28c29.002 0 52.513 23.512 52.513 52.513v64.185"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(320.806 428.723)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0v14.959c0 12.998-10.536 23.534-23.533 23.534h-.001c-12.997 0-23.533-10.536-23.533-23.534V0"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(423.908 441.928)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0c3.582-19.109 20.343-33.574 40.492-33.574 20.149 0 36.909 14.465 40.491 33.574"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(359.883 273.706)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                </g>
                              </g></svg
                            ></span>
                          <div class="d-flex flex-column">
                            <p>
                              Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Laboriosam dicta consequuntur
                              harum.
                            </p>
                            <div class="d-flex align-items-center justify-content-between">
                              <span class="time">Monday,7:01 AM</span>
                              <span class="date">Nov 29,2024</span>

                            </div>
                          </div>
                        </li>
                        <li class="d-flex gap-3">
                          <span class="icon"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="16"
                            height="16"
                            x="0"
                            y="0"
                            viewBox="0 0 682.667 682.667"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            xmlSpace="preserve"
                          >
                              <g>
                                <defs>
                                  <clipPath
                                    id="a"
                                    clipPathUnits="userSpaceOnUse"
                                  >
                                    <path
                                      d="M0 512h512V0H0Z"
                                      fill="#000000"
                                      opacity="1"
                                      data-original="#000000"
                                    ></path>
                                  </clipPath>
                                </defs>
                                <path
                                  d="M0 0h208.869"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 224.087)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 315.984)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 407.883)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <g
                                  clip-path="url(#a)"
                                  transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
                                >
                                  <path
                                    d="M0 0v8.891c0 31.975-25.922 57.897-57.898 57.897-31.976 0-57.898-25.922-57.898-57.897V0c0-34.55-15.647-67.199-42.481-88.84a10.024 10.024 0 0 1-2.65-3.228c-3.361-6.568 1.409-14.367 8.787-14.367H36.344c7.378 0 12.147 7.799 8.787 14.367a10.024 10.024 0 0 1-2.65 3.228A114.135 114.135 0 0 0 0 0Z"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(458.272 380.14)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0h-260.793c-29.002 0-52.513-23.511-52.513-52.513V-257.5c0-29.001 23.511-52.513 52.513-52.513h4.969c4.543 0 7.979-4.11 7.174-8.581l-12.939-71.841c-.973-5.402 5.522-8.907 9.503-5.127l85.195 80.873a17.024 17.024 0 0 0 11.719 4.676H72.28c29.002 0 52.513 23.512 52.513 52.513v64.185"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(320.806 428.723)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0v14.959c0 12.998-10.536 23.534-23.533 23.534h-.001c-12.997 0-23.533-10.536-23.533-23.534V0"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(423.908 441.928)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0c3.582-19.109 20.343-33.574 40.492-33.574 20.149 0 36.909 14.465 40.491 33.574"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(359.883 273.706)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                </g>
                              </g></svg
                            ></span>
                          <div class="d-flex flex-column">
                            <p>
                              Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Laboriosam dicta consequuntur
                              harum.
                            </p>
                            <div class="d-flex align-items-center justify-content-between">
                              <span class="time">Monday,7:01 AM</span>
                              <span class="date">Nov 29,2024</span>

                            </div>
                          </div>
                        </li>
                        <li class="d-flex gap-3">
                          <span class="icon"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="16"
                            height="16"
                            x="0"
                            y="0"
                            viewBox="0 0 682.667 682.667"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            xmlSpace="preserve"
                          >
                              <g>
                                <defs>
                                  <clipPath
                                    id="a"
                                    clipPathUnits="userSpaceOnUse"
                                  >
                                    <path
                                      d="M0 512h512V0H0Z"
                                      fill="#000000"
                                      opacity="1"
                                      data-original="#000000"
                                    ></path>
                                  </clipPath>
                                </defs>
                                <path
                                  d="M0 0h208.869"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 224.087)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 315.984)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <path
                                  d="M0 0h173.522"
                                  style={{
                                    strokeWidth: "15",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeMiterlimit: "10",
                                    strokeDasharray: "none",
                                    strokeOpacity: "1",
                                  }}
                                  transform="matrix(1.33333 0 0 -1.33333 109.841 407.883)"
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="15"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-miterlimit="10"
                                  stroke-dasharray="none"
                                  stroke-opacity=""
                                  data-original="#000000"
                                ></path>
                                <g
                                  clip-path="url(#a)"
                                  transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
                                >
                                  <path
                                    d="M0 0v8.891c0 31.975-25.922 57.897-57.898 57.897-31.976 0-57.898-25.922-57.898-57.897V0c0-34.55-15.647-67.199-42.481-88.84a10.024 10.024 0 0 1-2.65-3.228c-3.361-6.568 1.409-14.367 8.787-14.367H36.344c7.378 0 12.147 7.799 8.787 14.367a10.024 10.024 0 0 1-2.65 3.228A114.135 114.135 0 0 0 0 0Z"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(458.272 380.14)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0h-260.793c-29.002 0-52.513-23.511-52.513-52.513V-257.5c0-29.001 23.511-52.513 52.513-52.513h4.969c4.543 0 7.979-4.11 7.174-8.581l-12.939-71.841c-.973-5.402 5.522-8.907 9.503-5.127l85.195 80.873a17.024 17.024 0 0 0 11.719 4.676H72.28c29.002 0 52.513 23.512 52.513 52.513v64.185"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(320.806 428.723)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0v14.959c0 12.998-10.536 23.534-23.533 23.534h-.001c-12.997 0-23.533-10.536-23.533-23.534V0"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(423.908 441.928)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M0 0c3.582-19.109 20.343-33.574 40.492-33.574 20.149 0 36.909 14.465 40.491 33.574"
                                    style={{
                                      strokeWidth: "15",
                                      strokeLinecap: "round",
                                      strokeLinejoin: "round",
                                      strokeMiterlimit: "10",
                                      strokeDasharray: "none",
                                      strokeOpacity: "1"
                                    }}
                                    transform="translate(359.883 273.706)"
                                    fill="none"
                                    stroke="#000000"
                                    stroke-width="15"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-miterlimit="10"
                                    stroke-dasharray="none"
                                    stroke-opacity=""
                                    data-original="#000000"
                                  ></path>
                                </g>
                              </g></svg
                            ></span>
                          <div class="d-flex flex-column">
                            <p>
                              Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Laboriosam dicta consequuntur
                              harum.
                            </p>
                            <div class="d-flex align-items-center justify-content-between">
                              <span class="time">Monday,7:01 AM</span>
                              <span class="date">Nov 29,2024</span>

                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="footer">
                      <a href="#">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="16"
                          height="16"
                          x="0"
                          y="0"
                          viewBox="0 0 32 32"
                          style={{ enableBackground: "new 0 0 512 512" }}
                          xmlSpace="preserve"
                          class=""
                        >
                          <g>
                            <path
                              d="M14.874 19.242a.996.996 0 0 0 .754-.343l8.607-9.864A1 1 0 1 0 22.73 7.72l-8.607 9.864a1 1 0 0 0 .753 1.658z"
                              fill="currentColor"
                              opacity="1"
                              data-original="#000000"
                            ></path>
                            <path
                              d="M30.788 6.758a1 1 0 0 0-1.411.096L15.305 22.981l-6.802-7.55a1 1 0 1 0-1.486 1.338l3.969 4.406-1.573 1.805-6.8-7.549a1 1 0 1 0-1.486 1.34l7.556 8.386c.19.21.46.33.743.33h.008a1 1 0 0 0 .746-.342l2.154-2.473 2.24 2.486c.19.21.46.33.743.33h.007a1 1 0 0 0 .746-.342L30.884 8.17a1 1 0 0 0-.096-1.411z"
                              fill="currentColor"
                              opacity="1"
                              data-original="#000000"
                            ></path>
                          </g>
                        </svg>
                        Mark all as read
                      </a>
                      <button class="btn btn-primary p-1 px-3 rounded">
                        View all notification
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="dropdown">
                    <button
                      class="btn dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="far fa-user"></i>
                    </button>
                    <ul class="dropdown-menu shadow">
                      <li>
                        <a class="dropdown-item" href="profile.html">
                          <i class="far fa-user"></i>
                          Profile</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#">
                          <i class="fas fa-cog"></i>
                          Setting</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="login.html">
                          <i class="fas fa-door-open"></i>
                          Logout</a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </>)
}