import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import { useEffect, useState } from "react";
import { ApiUrl } from "../../utility/ApiUrls";
import { callApi } from "../../utility/useapi";
import DocumentModal from "../VerifiedUsers/DocumentModal";
import moment from "moment";
import { toast } from "react-toastify";
import { ROUTES } from "../../Routes/ROUTES";
import CommonTable from "../../Components/Common/CommonTable";
import ActivityModal from "./Components/ActivityModal";
import MainHeading from "../../Components/Common/MainHeading";

export default function UserDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [CallLogsData, setCallLogsData] = useState([]);
  const [ScannerData, setScannerData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchScannerParams, setSearchScannerParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page")) || 1;
  const [modal, setModal] = useState(false);
  const [ActivityData, setActivityData] = useState([]);

  const toggleModal = () => {
    setModal(!modal);
  };

  console.log("searchParams", searchParams);

  const [pageStates, setPageStates] = useState({
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });

  const [ScannerpageStates, setScannerPageStates] = useState({
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });
  const TableHeading = [
    {
      label: "#",
      value: "index",
    },
    {
      label: "Mobile No.",
      value: "phone",
    },
    {
      label: "Scan Date/Time",
      value: "scanDateTime",
    },
    {
      label: "Qr Details",
      value: "qrHexaKey",
    },

    // {
    //   label: "Status",
    //   value: "status",
    // },
  ];

  const ActionData = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",
      action: () => {
        // handle action
      },
    },

    {
      icon: <i class="fas fa-trash-alt"></i>,
      label: "Deactivate",
      action: () => {
        // handle action
      },
    },
  ];

  const TableHeadingCallLogs = [
    {
      label: "#",
      value: "index",
    },
    {
      label: "Date & Time",
      value: "dateTime",
    },
    {
      label: "Event Type",
      value: "callStatus",
    },
    {
      label: "Caller/Reciver",
      value: "callType",
    },
    {
      label: "Call Duration",
      value: "duration",
    },
    {
      label: "Number",
      value: "callerNumber",
    },
    {
      label: "Recording",
      value: "recordingURL",
    },

    // {
    //   label: "Status",
    //   value: "status",
    // },
  ];

  const ActionDataCallLogs = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",
      action: () => {
        // handle action
      },
    },
    // {
    //   icon: <i class="fas fa-file"></i>,
    //   label: "Documents",
    //   action: () => {
    //     // handle action
    //   },
    // },
    {
      icon: <i class="fas fa-trash-alt"></i>,
      label: "Deactivate",
      action: () => {
        // handle action
      },
    },
  ];

  const callLogsPageHandler = (newPage) => {
    setPageStates((prevState) => ({
      ...prevState,
      currentPage: 1,
      tableLoader: true,
    }));

    callApi(
      `${ApiUrl?.getCallLogs}?filter=searchQuery:${userDetailsData?.phone}&pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({
        ...prevState,
        tableLoader: false,
      }));
      if (response?.status === true) {
        setCallLogsData(response?.data?.logs);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
        toast.error("Failed to fetch call logs.");
      }
    });
  };

  useEffect(() => {
    callLogsPageHandler(initialPage); // Fetch data for the current page on initial load
  }, [initialPage]);

  const [userDetailsData, setUserDetailsData] = useState({
    id: "",
    userGUID: "",
    name: "",
    phone: "",
    aadhaarNumber: "",
    email: "",
    alternatePhone: "",
    aadharPhoto: null,
    address: "",
    state: "",
    city: "",
    gender: "",
    dob: "",
    dlNumber: "",
    expiryDate: "",
    dlStatus: "",
    isDocVerified: false,
    bloodGroup: "",
    disease: "",
    vehicle: [],
  });

  const ScannerActivityPageHandler = (newPage) => {
    setScannerPageStates((prevState) => ({
      ...prevState,
      currentPage: 1,
      tableLoader: true,
    }));

    callApi(
      `${ApiUrl?.getScannerActivity}?filter=searchQuery:${userDetailsData?.phone}&pageNo=1&pageSize=10`
    ).then((response) => {
      //  setIsLoading(false);
      if (response?.status === true) {
        setScannerData(response?.data?.user); // Assuming the response contains document data
      } else {
        toast.error("Failed to fetch document data.");
      }
    });

    callApi(
      `${ApiUrl?.getCallLogs}?filter=searchQuery:${userDetailsData?.phone}&pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({
        ...prevState,
        tableLoader: false,
      }));
      if (response?.status === true) {
        setCallLogsData(response?.data?.logs);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
        toast.error("Failed to fetch call logs.");
      }
    });
  };

  useEffect(() => {
    ScannerActivityPageHandler(initialPage); // Fetch data for the current page on initial load
  }, [initialPage]);

  // Fetch User Data
  useEffect(() => {
    if (location?.state?.userGUID) {
      fetchUserDetails();
    } else {
      console.error("User GUID is missing in location state");
    }
  }, [location]);

  const fetchUserDetails = async () => {
    try {
      const response = await callApi(
        `${ApiUrl?.userDetail}${location?.state?.userGUID}`
      );
      if (response?.status) {
        const user = response?.data?.user || {};
        setUserDetailsData({
          id: user.id || "",
          profilePhoto: user?.profilePhoto || {},
          userGUID: user.userGUID || "",
          name: user.name || "",
          phone: user.phone || "",
          aadhaarNumber: user.aadhaarNumber || "",
          email: user.email || "",
          alternatePhone: user.alternatePhone || "",
          aadharPhoto: user.aadharPhoto || null,
          address: user.address || "",
          state: user.state || "",
          city: user.city || "",
          gender: user.gender || "",
          dob: user.dob ? moment(user.dob).format("DD-MM-YYYY") : "",
          dlNumber: user.dlNumber || "",
          expiryDate: user.expiryDate
            ? moment(user.expiryDate).format("DD-MM-YYYY")
            : "",
          dlStatus: user.dlStatus || "",
          isDocVerified: user.isDocVerified || false,
          bloodGroup: user.bloodGroup || "",
          disease: user.disease || "",
          vehicle: user.vehicle || [],
        });
      } else {
        console.error("Failed to fetch user details:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
  const fetchDocumentData = (userId) => {
    //  setIsLoading(true);
    callApi(`${ApiUrl?.getUserDocuments}${userId}`).then((response) => {
      //  setIsLoading(false);
      if (response?.status === true) {
        setDocumentData(response?.data); // Assuming the response contains document data
        setIsModalOpen(true); // Open the modal
      } else {
        toast.error("Failed to fetch document data.");
      }
    });
  };

  const fetchActivityData = (userid) => {
    //  setIsLoading(true);
    callApi(`${ApiUrl?.getUserActivity}${userid}`).then((response) => {
      //  setIsLoading(false);
      if (response?.status === true) {
        setActivityData(response?.data?.logData); // Assuming the response contains document data
        setModal(true); // Open the modal
      } else {
        toast.error("Failed to fetch document data.");
      }
    });
  };
  const scanerActivity = () => {
    //  setIsLoading(true);
    // /api/User/getGuestUsersList?filters=searchQuery%3A94&pageNo=1&pageSize=10
  };

  const backButtonHandler = () => navigate(-1);

  return (
    <MainLayout>
      <div className="content-head mb-5 d-flex align-items-start justify-content-between">
        <MainHeading
          title={"User Detail"}
          backButtonHandler={backButtonHandler}
        />
      </div>
      <div className="d-flex justify-content-between">
        <nav>
          <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
            <button
              className="btn btn-tabs px-5 rounded-0 active"
              data-bs-toggle="tab"
              data-bs-target="#tab-one"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="false"
              tabIndex={-1}
            >
              Details
            </button>
            <button
              className="btn btn-tabs px-5 rounded-0"
              data-bs-toggle="tab"
              data-bs-target="#tab-two"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="true"
            >
              QR Scanner Activity
            </button>
            <button
              className="btn btn-tabs px-5 rounded-0"
              data-bs-toggle="tab"
              data-bs-target="#tab-three"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="true"
            >
              Call Logs
            </button>
          </div>
        </nav>
        <div>
          <button
            onClick={() => fetchActivityData(userDetailsData.userGUID)}
            className="btn btn-primary"
          >
            Activity Log
          </button>
        </div>
      </div>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="tab-one"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabIndex={0}
        >
          <div className="card manage-user-list">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                <h4 className="d-flex align-items-center mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle cx={10} cy={10} r={2} fill="#000000" />
                  </svg>
                  Basic Details
                </h4>
                <div>
                  <p>
                    <span className="text-muted fs-5">Status :-</span>
                    <span className="badge bg-green-25 fw-normal rounded-pill">
                      Active
                    </span>
                  </p>
                </div>
              </div>
              <ul className="row list-unstyled">
                <li className="col-12">
                  <p>
                    <img
                      className="detail-img"
                      src={`data:image/jpeg;base64,${userDetailsData?.profilePhoto}`}
                      alt=""
                    />
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      User Name :-
                    </span>
                    {userDetailsData?.name}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">DOB :-</span>
                    {userDetailsData?.dob}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">Contact :-</span>
                    +91-{userDetailsData?.phone}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Contact (Alternate) :-
                    </span>
                    --
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">Address :-</span>
                    {userDetailsData?.address}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">State :-</span>
                    {userDetailsData?.state}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">City :-</span>
                    {userDetailsData?.city}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      View Documents :-
                    </span>
                    <button
                      className="btn btn-primary px-5"
                      onClick={() =>
                        fetchDocumentData(userDetailsData.userGUID)
                      }
                    >
                      View
                    </button>
                  </p>
                </li>
              </ul>
              <h4 className="d-flex align-items-center mb-4 border-bottom pb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle cx={10} cy={10} r={2} fill="#000000" />
                </svg>
                Health Details
              </h4>
              <ul className="row list-unstyled">
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Blood Group :-
                    </span>
                    {userDetailsData?.bloodGroup}
                  </p>
                </li>
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">Diseases :-</span>
                    {userDetailsData?.disease}
                  </p>
                </li>
              </ul>
              <h4 className="d-flex align-items-center mb-4 border-bottom pb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle cx={10} cy={10} r={2} fill="#000000" />
                </svg>
                ID Details
              </h4>
              <ul className="row list-unstyled">
                <li className="col-12 col-md-6 col-lg-4">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Aadhar No. :-
                    </span>
                    {userDetailsData?.aadhaarNumber}
                  </p>
                </li>
              </ul>
              {/* <h4 class="d-flex align-items-center mb-4 border-bottom pb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle cx="10" cy="10" r="2" fill="#000000"></circle>
                </svg>
                License Details
              </h4>
              <ul class="row list-unstyled">
                <li class="col-12 col-md-6 col-lg-4">
                  <p>
                    <span class="text-muted fs-5 d-block"
                      >Vehicle type :-</span
                    >
                    N/A
                  </p>
                </li>
                <li class="col-12 col-md-6 col-lg-4">
                  <p>
                    <span class="text-muted fs-5 d-block"
                      >Vehicle Registration No. :-</span
                    >
                    N/A
                  </p>
                </li>
                <li class="col-12 col-md-6 col-lg-4">
                  <p>
                    <span class="text-muted fs-5 d-block"
                      >Insurance Company :-</span
                    >
                    N/A
                  </p>
                </li>
                <li class="col-12 col-md-6 col-lg-4">
                  <p>
                    <span class="text-muted fs-5 d-block"
                      >Policy Number :-</span
                    >
                    N/A
                  </p>
                </li>
                <li class="col-12 col-md-6 col-lg-4">
                  <p>
                    <span class="text-muted fs-5 d-block"
                      >Expiry Date :-</span
                    >
                    N/A
                  </p>
                </li>
              </ul> */}
              <h4 className="d-flex align-items-center mb-4 border-bottom pb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle cx={10} cy={10} r={2} fill="#000000" />
                </svg>
                SoS Details
              </h4>
              <ul className="row list-unstyled">
                <li className="col-12 col-md-6">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Member Name :-
                    </span>
                    N/A
                  </p>
                </li>
                <li className="col-12 col-md-6">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Mobile No. :-
                    </span>
                    N/A
                  </p>
                </li>
                <li className="col-12 col-md-6">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Member Name :-
                    </span>
                    N/A
                  </p>
                </li>
                <li className="col-12 col-md-6">
                  <p>
                    <span className="text-muted fs-5 d-block">
                      Mobile No. :-
                    </span>
                    N/A
                  </p>
                </li>
              </ul>
              <h4 className="d-flex align-items-center mb-4 border-bottom pb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle cx={10} cy={10} r={2} fill="#000000" />
                </svg>
                Vehicle Details
              </h4>
              <div className="row">
                {userDetailsData &&
                  userDetailsData?.vehicle?.map((res, index) => {
                    return (
                      <div className="col-12 col-md-4">
                        <div className="card vehicle-card">
                          <div className="card-body d-flex flex-column gap-2">
                            <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
                              <span className="text-muted fs-5 d-block">
                                Vehicle {index + 1}
                              </span>
                              <span className="badge bg-green-25 fw-normal rounded-pill">
                                Active
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <p className="m-0">
                                <span className="text-muted fs-5 d-block">
                                  Type:
                                </span>
                                {res?.vehicleType}
                              </p>
                              <p className="m-0">
                                <span className="text-muted fs-5 d-block">
                                  Number:
                                </span>
                                {res?.vehicleNumber}
                              </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <p className="m-0">
                                <span className="text-muted fs-5 d-block">
                                  Make and Model:
                                </span>
                                {res?.makeModel}
                              </p>
                            </div>
                            <a
                              onClick={() =>
                                navigate(ROUTES?.vehicleDetials, {
                                  state: res,
                                })
                              }
                              className="btn btn-sm btn-primary"
                            >
                              View
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="tab-two"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabIndex={0}
        >
          <div className="card manage-user-list">
            <CommonTable
              TableHeading={TableHeading}
              TableData={ScannerData}
              ActionData={ActionData}
              currentPage={pageStates?.currentPage}
              totalPages={pageStates?.totalPages}
              PageChangeHandler={ScannerActivityPageHandler}
            />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="tab-three"
          role="tabpanel"
          tabIndex={0}
        >
          <CommonTable
            TableHeading={TableHeadingCallLogs}
            TableData={CallLogsData}
            // ActionData={ActionDataCallLogs}
            currentPage={pageStates?.currentPage}
            totalPages={pageStates?.totalPages}
            PageChangeHandler={callLogsPageHandler}
          />

          <DocumentModal
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            documentData={documentData}
            // handleAccept={handleAccept}
            // handleReject={handleReject}
          />
          <ActivityModal
            isOpen={modal}
            toggleActivity={toggleModal}
            activityData={ActivityData}
          />
        </div>
      </div>
    </MainLayout>
  );
}
