import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { toast } from "react-toastify";

const ComplaintModal = ({
  isOpen,
  toggle,
  documentData,
  handleAccept,
  handleReject,
}) => {
  //   if (!documentData) return null;

  return (
    <Modal
      className="modal-dialog modal-xl modal-dialog-centered dlt-modal"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Duration</ModalHeader>
      <ModalBody>
        <div className="modal-body  p-4">
          <form action="">
            <div className="card shadow-0 bg-transparent mb-0">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12 ">
                    <ul className="row list-unstyled">
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Name :-
                          </span>
                          {documentData?.name}
                        </p>
                      </li>
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Email :-
                          </span>
                          {documentData?.email}
                        </p>
                      </li>
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Mobile No. :-
                          </span>
                          {documentData?.phone}
                        </p>
                      </li>
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Reason :-
                          </span>
                          {documentData?.reason}
                        </p>
                      </li>
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Request Id :-
                          </span>
                          {documentData?.id}
                        </p>
                      </li>
                      <li className="col-12 ">
                        <p>
                          <span className="text-muted fs-5 d-block">
                            Description :-
                          </span>
                          {documentData?.message}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 ">
                    <div className="form-group mb-3">
                      <label htmlFor="enddate" className="form-label">
                        Remarks
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        defaultValue={""}
                      />
                      <div className="form-text text-danger d-none">
                        This field is Required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
        <Button color="success" onClick={() => handleAccept(documentData)}>
          Accept
        </Button>
        <Button color="danger" onClick={() => handleReject(documentData)}>
          Reject
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ComplaintModal;
