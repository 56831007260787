import { Route, Routes } from "react-router-dom";
import { ROUTES } from "./ROUTES";
import Login from "../Pages/Auth/Login";
import Dashboard from "../Pages/Dashboard";
import VerifiedUsers from "../Pages/VerifiedUsers";
import UnverifiedUsers from "../Pages/UnverifiedUsers";
import Category from "../Pages/Category";
import GenerateQR from "../Pages/GenerateQR";
import Complaints from "../Pages/Complaints";
import Banner from "../Pages/Banner";
import UserDetails from "../Pages/UserDetails";
import VehicleDetails from "../Pages/Vehicle";
import VehicleList from "../Pages/Vehicle";
import VehicleDetials from "../Pages/VehicalDetials";
import BannerDetials from "../Pages/Banner/BannerDetials";
import BlackSpot from "../Pages/BlackSpot";
import GuestUserDetails from "../Pages/GuestUserDetials";

const CommonRoutes = () => {
  const RoutesArray = [
    {
      path: ROUTES?.login_route,
      element: <Login />,
    },
    {
      path: ROUTES?.dashboard,
      element: <Dashboard />,
    },
    {
      path: ROUTES?.verifiedUsers,
      element: <VerifiedUsers />,
    },
    {
      path: ROUTES?.UnverifiedUsers,
      element: <UnverifiedUsers />,
    },
    {
      path: ROUTES?.category,
      element: <Category />,
    },
    {
      path: ROUTES?.generateQR,
      element: <GenerateQR />,
    },
    {
      path: ROUTES?.complaints,
      element: <Complaints />,
    },
    {
      path: ROUTES?.banner,
      element: <Banner />,
    },
    {
      path: ROUTES?.userDetails,
      element: <UserDetails />,
    },
    {
      path: ROUTES?.vehicle,
      element: <VehicleList />,
    },
    {
      path: ROUTES?.vehicleDetials,
      element: <VehicleDetials />,
    },
    {
      path: ROUTES?.bannerDetials,
      element: <BannerDetials />,
    },
    {
      path: ROUTES?.blackSpot,
      element: <BlackSpot />,
    },
    {
      path: ROUTES?.guestUserDetials,
      element: <GuestUserDetails />,
    },
  ];
  return (
    <Routes>
      {RoutesArray?.map((route, index) => (
        <Route path={route?.path} key={index} element={route?.element} />
      ))}
    </Routes>
  );
};
export default CommonRoutes;
